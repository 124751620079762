export const SELECT_ONE: string = 'Select One'

export enum Island {
    ANEGADA = 'Anegada',
    BEEF_ISLAND = 'Beef Island',
    JOST_VAN_DKYE = 'Jost Van Dyke',
    SCRUB_ISLAND = 'Scrub Island',
    TORTOLA = 'Tortola',
    VIRGIN_GORDA = 'Virgin Gorda',
}

export type GrowlType = 'success' | 'info' | 'warning' | 'error'

export const AUTH0_AUDIENCE: string = process.env.REACT_APP_AUTH0_AUDIENCE!
export const AUTH0_SCOPES: string = process.env.REACT_APP_AUTH0_SCOPES!