import React from 'react'
import {GridRowId} from '@mui/x-data-grid'
import {IContact} from 'types'
import {apiDelete, apiGet, apiPost} from 'functions/ApiUtils'
import {growl} from 'functions/CommonUtils'

export const apiListContacts = (accessToken: string|undefined,
                                setData: Function,
                                setLoaded: Function,
                                associationFilter?: boolean | undefined) => {
    apiGet(accessToken, `/contacts${associationFilter !== undefined ? `?associated=${associationFilter}` : ''}`)
        .then(response => {
            if (response === undefined) {
                response = {data: {}}
            }
            setData(response.data as unknown as IContact[])
            setTimeout(() => {setLoaded(true)}, 300)
        })
}

export const apiUpsertContact = (   accessToken: string|undefined,
                                    setData: Function,
                                    data: IContact[],
                                    payload: any,
                                    growlMessage?: string,
                                    setGrowlMessage?: Function,
                                    setGrowlType?: Function,
                                    setGrowlInstance?: Function) => {
    apiPost(accessToken, '/contacts', payload)
        .then(response => {
            if (response === undefined) {
                response = {data: {}}
            }
            const upsertedContact: IContact = response.data as unknown as IContact
            const dataCopy = [...data]
            let found = false
            dataCopy.forEach((contact: IContact) => {
                if (contact.id === upsertedContact.id) {
                    found = true
                }
            })
            if (!found) {
                setData([upsertedContact, ...dataCopy])
            } else {
                setData(dataCopy)
            }
            growl(growlMessage, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
        })
}

export const apiDeleteContact = (   accessToken: string|undefined,
                                    id: GridRowId,
                                    setData: Function,
                                    data: IContact[],
                                    growlMessage?: string,
                                    setGrowlMessage?: Function,
                                    setGrowlType?: Function,
                                    setGrowlInstance?: Function) => {
    apiDelete(accessToken, `/contacts/${id}`)
        .then(response => {
            if (response?.data === true) {
                const dataCopy = [...data].filter(contact => contact.id !== id)
                setData(dataCopy)
                growl(growlMessage, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
            } else {
                const msg = `Could not remove contact ${id}`
                growl(msg, 'error', setGrowlMessage, setGrowlType, setGrowlInstance)
            }
        })
}
