import React, {FC} from 'react'

//-- MATERIAL UI
import {ButtonGroup} from '@mui/material'

//-- FONT AWESOME
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faFilter,
} from '@fortawesome/free-solid-svg-icons'

//-- PAGE COMPONENTS
import {SecondaryButton} from 'components/button/Button'

//-- STYLES
import './CommonFilter.scss'


interface ICommonFilterProps {
    id: string,
    title: string,
    associationFilter: boolean|undefined,
    setAssociationFilter: Function,
}
export const CommonFilter: FC<ICommonFilterProps> = ({
    id,
    title,
    associationFilter,
    setAssociationFilter,
}: ICommonFilterProps) => {
    const filterIcon = (
        <FontAwesomeIcon icon={faFilter} fixedWidth={true}/>
    )

    return (
        <div id={id} className='base_filters'>
            <div className='filters_label'>
                {filterIcon}
                {title}:
            </div>
            <ButtonGroup className='filters_buttongroup'>
                <SecondaryButton id={`${id}_filterAll`} onClick={() => setAssociationFilter(undefined)}
                                 label='All'
                                 className={associationFilter === undefined ? 'filter_selected' : 'filter_unselected'}/>

                <SecondaryButton id={`${id}_filterAssociated`} onClick={() => setAssociationFilter(true)}
                                 label='Associated'
                                 className={associationFilter === true ? 'filter_selected' : 'filter_unselected'}
                />

                <SecondaryButton id={`${id}_filterNotAssociated`} onClick={() => setAssociationFilter(false)}
                                 label='Not Associated'
                                 className={associationFilter === false ? 'filter_selected' : 'filter_unselected'}/>
            </ButtonGroup>
        </div>
    )
}