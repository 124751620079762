import React from 'react'
import { AppMenu } from 'layout/leftNavBar/appMenu/AppMenu'
import { Logo } from 'layout/leftNavBar/logo/Logo'
import { MenuItemType, menuItems } from 'enums/Types'
import './LeftNavBar.scss'

interface ILeftNavBarProps {
    id: string,
    handleMenuNav: Function,
    selectedMenuItem: MenuItemType,
    setSelectedMenuItem: Function,
    onNavigate: Function,
}

export const LeftNavBar: React.FC<ILeftNavBarProps> = ({
    id,
    handleMenuNav,
    selectedMenuItem,
    setSelectedMenuItem,
    onNavigate,
}: ILeftNavBarProps) => {

    return (
        <aside id={id} className='left-nav-bar'>
            <Logo id={`${id}_logo`} />
            <AppMenu id={`${id}_menu`} menuItems={menuItems} handleMenuNav={handleMenuNav} selectedMenuItem={selectedMenuItem} setSelectedMenuItem={setSelectedMenuItem} onNavigate={onNavigate} />
        </aside>
    )
}