import React, {useState, FC} from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity'
import { makeStyles } from '@mui/styles'
import './Table.scss'

/**
 * DEFAULT TABLE STYLES
 */
const useStyles = makeStyles({
    root: {
        '& .MuiDataGrid-selectedRowCount': {
            fontSize: '1rem',
            fontWeight: 'bold',
        },
        '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#3F4A59',
        },
        '& .MuiDataGrid-checkboxInput': {
            color: '#3F4A59',
        },
        '& .MuiDataGrid-columnHeaderCheckbox .MuiTouchRipple-root, & .MuiDataGrid-columnHeaderCheckbox svg': {
            color: '#FFFFFF',
        },
        '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-sortIcon, & .MuiDataGrid-filterIcon, & .MuiDataGrid-menuIconButton': {
            color: '#FFFFFF',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeader--filtered, & .MuiDataGrid-columnHeader--sorted': {
            backgroundColor: '#496476',
        },
        '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
        },
        '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: '#cddde7',
        },
        '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: '#e2ebf1',
        },
    },
});

/**
 * TABLE COMPONENT PROPERTIES
 */
interface ITableProps {
    id: string,
    columns: GridColDef[],
    rows: any[],
    height?: string,
    checkboxSelection?: boolean,

}

/**
 * TABLE COMPONENT DEFINITION
 * @param id
 * @param columns
 * @param rows
 * @param height
 * @param checkboxSelection
 * @constructor
 */
export const Table: FC<ITableProps> = ({
    id,
    columns,
    rows,
    height,
    checkboxSelection,
}: ITableProps) => {

    const DEFAULT_PAGE_SIZE: number = 10
    const defaultPageOptions: number[] = [5, 10, 25, 50, 100]
    const classes = useStyles();
    const [selectionModel, setSelectionModel] = useState<any[]>([]);
    const initialState: GridInitialStateCommunity = {pagination: { paginationModel: { pageSize: DEFAULT_PAGE_SIZE}}}

    return (
        <div id={id} className={'data-table'} style={{ height: height || '85%', margin: '1rem 0' }}>
            <DataGrid
                className={classes.root}
                checkboxSelection={checkboxSelection}
                disableRowSelectionOnClick
                rows={rows}
                columns={columns}
                autoHeight={rows?.length === 0}
                pageSizeOptions={defaultPageOptions}
                onRowSelectionModelChange={(newSelection:any[]) => {
                    setSelectionModel(newSelection)
                    return newSelection;
                }}
                rowSelectionModel={selectionModel}
                initialState={initialState}
            />
        </div>
    );
}
