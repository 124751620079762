import React from 'react'
import {GridRowId} from '@mui/x-data-grid'
import {ILocation} from 'types'
import {apiDelete, apiGet, apiPost} from 'functions/ApiUtils'
import {growl} from 'functions/CommonUtils'

export const apiListLocations = (   accessToken: string|undefined,
                                    setData: Function,
                                    setLoaded: Function,
                                    associationFilter?: boolean | undefined) => {
    apiGet(accessToken, `/locations${associationFilter !== undefined ? `?associated=${associationFilter}` : ''}`)
        .then(response => {
            if (response === undefined) {
                response = {data: {}}
            }
            setData(response.data as unknown as ILocation[])
            setTimeout(() => {setLoaded(true)}, 300)
        })
}

export const apiUpsertLocation = (  accessToken: string|undefined,
                                    setData: Function,
                                    data: ILocation[],
                                    payload: any,
                                    growlMessage?: string,
                                    setGrowlMessage?: Function,
                                    setGrowlType?: Function,
                                    setGrowlInstance?: Function) => {
    apiPost(accessToken, '/locations', payload)
        .then(response => {
            if (response === undefined) {
                response = {data: {}}
            }
            const upsertedLocation: ILocation = response.data as unknown as ILocation
            const dataCopy = [...data]
            let found = false
            dataCopy.forEach((location: ILocation) => {
                if (location.id === upsertedLocation.id) {
                    location.island = upsertedLocation.island
                    found = true
                }
            })
            if (!found) {
                setData([upsertedLocation, ...dataCopy])
            } else {
                setData(dataCopy)
            }
            growl(growlMessage, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
        })
}

export const apiDeleteLocation = (  accessToken: string|undefined,
                                    id: GridRowId,
                                    setData: Function,
                                    data: ILocation[],
                                    growlMessage?: string,
                                    setGrowlMessage?: Function,
                                    setGrowlType?: Function,
                                    setGrowlInstance?: Function) => {
    apiDelete(accessToken, `/locations/${id}`)
        .then(response => {
            if (response?.data === true) {
                const dataCopy = [...data].filter(location => location.id !== id)
                setData(dataCopy)
                growl(growlMessage, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
            } else {
                const msg = `Could not remove location ${id}`
                growl(msg, 'error', setGrowlMessage, setGrowlType, setGrowlInstance)
            }
        })
}
