import React from 'react'
import {IContact} from 'types'

export const renderSelectedContact = (value: string[]) => {
    const contact: IContact = value && value.length > 0 && JSON.parse(value[0])
    return (
        <div title={`${contact.name}${(contact.phone1 || contact.phone2) && ` - Phone: ${contact.phone1 || contact.phone2}`}${(contact.email1 || contact.email2) && ` - Email: ${contact.email1 || contact.email2}`}`}>
            {contact.name}
        </div>
    )
}