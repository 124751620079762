import React from 'react'
import axios, { AxiosInstance, AxiosRequestConfig } from "axios"

type HTTPVerb = 'GET' | 'POST' | 'PUT' | 'DELETE'

export const getBaseURL = () => {
    const baseUrl = 'https://admin-api.gempropertiesbvi.com'
    return baseUrl
}

interface RetryConfig extends AxiosRequestConfig {
    retry: number
    retryDelay: number
}
const globalConfig: RetryConfig = {
    retry: 10,
    retryDelay: 1000,
    baseURL: getBaseURL(),
}

const customAxiosApi: AxiosInstance = axios.create({
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
    },
})

customAxiosApi.interceptors.response.use(undefined, (err) => {
    const { config, message } = err;
    if (!config || !config.retry) {
        return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes("timeout") || message.includes("Network Error"))) {
        return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise<void>((resolve) => {
        setTimeout(() => {
            resolve()
        }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => axios(config));
});

export const generateBearerToken = (accessToken: string|undefined): any => {
    return {'Authorization': `Bearer ${accessToken}`}
}

export const apiGet = async (accessToken: string|undefined, url: string): Promise<any> => {
    return callWithRetries('GET', url, undefined, generateBearerToken(accessToken))
}

export const apiPost = async (accessToken: string|undefined, url: string, payload: any): Promise<any> => {
    return callWithRetries('POST', url, payload, generateBearerToken(accessToken))
}

export const apiPut = async (accessToken: string|undefined, url: string, payload: any): Promise<any> => {
    return callWithRetries('PUT', url, payload, generateBearerToken(accessToken))
}

export const apiDelete = async (accessToken: string|undefined, url: string): Promise<any> => {
    return callWithRetries('DELETE', url, undefined, generateBearerToken(accessToken))
}

const callWithRetries = async (verb: HTTPVerb, url: string, payload?: any|undefined, additionalHeaders?: any|undefined): Promise<any> => {
    let response
    let config = globalConfig
    if (additionalHeaders) {
        config = {...globalConfig, headers: additionalHeaders}
    }
    try {
        switch (verb) {
            case 'GET': {
                response = await axios.get(url, config)
                break
            }
            case 'POST': {
                response = await axios.post(url, payload || {}, config)
                break
            }
            case 'PUT': {
                response = await axios.put(url, payload || {}, config)
                break
            }
            case 'DELETE': {
                response = await axios.delete(url, config)
                break
            }
            default: {
                console.error('Unsupported AXIOS Request', {verb}, {url})
                return []
            }
        }
        // console.log({verb}, {url}, {payload}, {response})
        return response
    } catch (error) {
        console.error({error})
        // if (response?.status === 401) {
        //     const access_token = await getNewAccessToken();
        //     axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        // } else {
        //     retries++;
        // }
    }
}