import React from 'react'
import {GridRowId} from '@mui/x-data-grid'
import {IProperty, PropertyStatus} from 'types'
import {apiListProperties, apiUpdatePropertyFeatured, apiUpdatePropertyStatus} from 'api'

export const getPropertyStatus = (status: PropertyStatus) => {
    return Object.keys(PropertyStatus)[Object.values(PropertyStatus).indexOf(status)]
}

export const applyPropertyStatus = (    accessToken: string|undefined,
                                        id: GridRowId,
                                        status: PropertyStatus,
                                        setData: Function,
                                        data: IProperty[],
                                        growlMessage?: string,
                                        setGrowlMessage?: Function,
                                        setGrowlType?: Function,
                                        setGrowlInstance?: Function) => {
    setTimeout(() => {
        const payload: any = {
            id: id,
            status: getPropertyStatus(status),
        }
        apiUpdatePropertyStatus(accessToken, id, getPropertyStatus(status), setData, data, growlMessage, setGrowlMessage, setGrowlType, setGrowlInstance)
            .then(() => {
                //-- DO NOTHING
            })
    })
}

export const applyPropertyFeatured = (  accessToken: string|undefined,
                                        id: GridRowId,
                                        featured: boolean|undefined,
                                        setData: Function,
                                        data: IProperty[],
                                        featuredFilter: boolean|undefined,
                                        setLoaded: Function,
                                        growlMessage?: string,
                                        setGrowlMessage?: Function,
                                        setGrowlType?: Function,
                                        setGrowlInstance?: Function) => {
    setTimeout(() => {
        const url: string = `/properties`
        apiUpdatePropertyFeatured(accessToken, id, featured, setData, data, growlMessage, setGrowlMessage, setGrowlType, setGrowlInstance)
            .then(() => {
                setTimeout(() => {
                    apiListProperties(accessToken, setData, setLoaded, featuredFilter)
                }, 300)
            }
        )
    })
}
