import React, {useEffect, useState} from 'react'

//-- AUTH0
import {useAccessToken} from 'hooks/useAccessToken'

//-- PAGE COMPONENTS
import {EmptyState} from 'components/emptyState/EmptyState'
import {LoadingState} from 'components/loadingState/LoadingState'

//-- UTILITIES AND TYPES
import {applyView} from 'functions/CommonUtils'
import {IContact, IFeature, ILocation, IProperty, IViewing} from 'types'
import {
    formatPropertyResults,
    formatFeatureResults,
    formatLocationResults,
    formatContactResults,
    formatViewingResults,
} from './helpers'

//-- API AND ACTIONS
import {apiListSearchResults} from 'api/SearchAPI'

//-- STYLES
import './Search.scss'


/***
 * INTERFACES
 */
interface ISearchProps {
    id?: string,
    searchText: string|undefined,
    setSelectedMenuItem: Function,
    isResultsLoaded: boolean,
    setIsResultsLoaded: Function,
    searchIcon: any,
}

/**
 * Functional Component - Search
 * @param id
 * @param searchText
 * @param setSelectedMenuItem
 * @param isResultsLoaded
 * @param setIsResultsLoaded
 * @param searchIcon
 * @constructor
 */
export const Search: React.FC<ISearchProps> = ({
    id = 'searchResultsPage',
    searchText,
    setSelectedMenuItem,
    isResultsLoaded,
    setIsResultsLoaded,
    searchIcon,
}: ISearchProps) => {
    //-- Local State
    const [accessToken] = useAccessToken()
    const [searchData, setSearchData] = useState<JSX.Element[]|undefined>()

    /***
     * USE EFFECT HOOKS
     */
    useEffect(() => {
        if (searchText) {
            apiListSearchResults(accessToken, searchText).then(response => {
                const output: JSX.Element[] = []
                Object.keys(response.data).map((key: string) => {
                    response.data[key].map((value: IContact|IProperty|IFeature|ILocation|IViewing) => {
                        const result: JSX.Element|undefined = formatSearchResult(key, value)
                        result && output.push(result)
                    })
                    setTimeout(() => {
                        setIsResultsLoaded(true)
                    }, 300)
                })
                setSearchData(output.sort((element1: JSX.Element, element2: JSX.Element) => {
                    const element1Key = element1.key as string
                    const element2Key = element2.key as string
                    return element1Key.localeCompare(element2Key)
                }))
            })
        }
    }, [searchText])


    /***
     * UTILITY FUNCTIONS
     */
    const formatSearchResult = (type: string,
                                result: IProperty | ILocation | IFeature | IContact | IViewing): JSX.Element | undefined => {
        switch(type)  {
            case 'properties': {
                const property: IProperty = result as IProperty
                return formatPropertyResults(property, searchText, type, applyView, setSelectedMenuItem)
            }
            case 'locations': {
                const location: ILocation = result as ILocation
                return formatLocationResults(location, searchText, type, applyView, setSelectedMenuItem)
            }
            case 'features': {
                const feature: IFeature = result as IFeature
                return formatFeatureResults(feature, searchText, type, applyView, setSelectedMenuItem)
            }
            case 'contacts': {
                const contact: IContact = result as IContact
                return formatContactResults(contact, searchText, type, applyView, setSelectedMenuItem)
            }
            case 'viewings': {
                const viewing: IViewing = result as IViewing
                return formatViewingResults(viewing, searchText, type, applyView, setSelectedMenuItem)
            }
        }
        return undefined
    }

    return (
        <div id={id} className={'search-result'}>
            {isResultsLoaded ? (
                <>
                    {searchText && searchData && searchData.length ? searchData.map((result, idx) => (
                        <div key={`${searchText}_${idx}`} className={'search-result-wrapper'}>{result}</div>
                    )) : (
                        <EmptyState id={'emptyState'} icon={searchIcon}
                                    caption={'No search results found'}
                        />
                    )}
                </>
            ) : (
                <LoadingState id={'searchResultsLoading'}/>
            )}

        </div>
    )
}