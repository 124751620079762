import React from 'react'
import {GridRowId} from '@mui/x-data-grid'
import {IProperty, IPhoto} from 'types'
import {apiDelete, apiGet, apiPost, generateBearerToken, getBaseURL} from 'functions/ApiUtils'
import {growl} from 'functions/CommonUtils'

export const apiListProperties = (  accessToken: string|undefined,
                                    setData: Function,
                                    setLoaded: Function,
                                    featuredFilter?: boolean | undefined) => {
    apiGet(accessToken, `/properties${featuredFilter !== undefined ? `?featured=${featuredFilter}` : ''}`)
        .then(response => {
            if (response === undefined) {
                response = {data: {}}
            }
            setData(response.data as unknown as IProperty[])
            setTimeout(() => {setLoaded(true)}, 300)
        })
}

export const apiUpsertProperty =
    async (
        accessToken: string|undefined,
        setData: Function,
        data: IProperty[],
        payload: any,
        growlMessage?: string,
        setGrowlMessage?: Function,
        setGrowlType?: Function,
        setGrowlInstance?: Function
    ): Promise<void> => {
        apiPost(accessToken, '/properties', payload)
            .then(response => {
                if (response === undefined) {
                    response = {data: {}}
                }
                const upsertedProperty: IProperty = response.data as unknown as IProperty
                const dataCopy = [...data]
                let found = false
                dataCopy.forEach((property: IProperty) => {
                    if (property.id === upsertedProperty.id) {
                        property.status = upsertedProperty.status
                        property.featured = upsertedProperty.featured
                        found = true
                    }
                })
                if (!found) {
                    setData([upsertedProperty, ...dataCopy])
                } else {
                    setData(dataCopy)
                }
                growl(growlMessage, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
                return response
            })
}

export const apiUpdatePropertyStatus =
    async (
        accessToken: string|undefined,
        id: GridRowId,
        status: string,
        setData: Function,
        data: IProperty[],
        growlMessage?: string,
        setGrowlMessage?: Function,
        setGrowlType?: Function,
        setGrowlInstance?: Function
    ): Promise<void> => {
        apiPost(accessToken, `/properties/${id}/${status}`, undefined)
            .then(response => {
                const upsertedProperty: IProperty = response.data as unknown as IProperty
                const dataCopy = [...data]
                let found = false
                dataCopy.forEach((property: IProperty) => {
                    if (property.id === upsertedProperty.id) {
                        property.status = upsertedProperty.status
                        found = true
                    }
                })
                if (!found) {
                    setData([upsertedProperty, ...dataCopy])
                } else {
                    setData(dataCopy)
                }
                growl(growlMessage, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
                return response
            })
    }

export const apiUpdatePropertyFeatured =
    async (
        accessToken: string|undefined,
        id: GridRowId,
        featured: boolean|undefined,
        setData: Function,
        data: IProperty[],
        growlMessage?: string,
        setGrowlMessage?: Function,
        setGrowlType?: Function,
        setGrowlInstance?: Function
    ): Promise<void> => {
        apiPost(accessToken, `/properties/${id}/${featured ? 'FEATURED' : 'NOT_FEATURED'}`, undefined)
            .then(response => {
                if (response === undefined) {
                    response = {data: {}}
                }
                const upsertedProperty: IProperty = response.data as unknown as IProperty
                const dataCopy = [...data]
                let found = false
                dataCopy.forEach((property: IProperty) => {
                    if (property.id === upsertedProperty.id) {
                        property.featured = upsertedProperty.featured
                        found = true
                    }
                })
                if (!found) {
                    setData([upsertedProperty, ...dataCopy])
                } else {
                    setData(dataCopy)
                }
                growl(growlMessage, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
                return response
            })
    }

export const apiDeleteProperty = (  accessToken: string|undefined,
                                    id: GridRowId,
                                    setData: Function,
                                    data: IProperty[],
                                    growlMessage?: string,
                                    setGrowlMessage?: Function,
                                    setGrowlType?: Function,
                                    setGrowlInstance?: Function) => {
    apiDelete(accessToken, `/properties/${id}`)
        .then(response => {
            if (response?.data === true) {
                const dataCopy = [...data].filter(property => property.id !== id)
                setData(dataCopy)
                growl(growlMessage, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
            } else {
                const msg = `Could not remove property ${id}`
                growl(msg, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
            }
        })
}

export const apiListPropertyPhotos = (  accessToken: string|undefined,
                                        id: number,
                                        setData: Function) => {
    apiGet(accessToken, `/properties/${id}/photos`)
        .then(response => {
            if (response === undefined) {
                response = {data: {}}
            }
            setData(response.data as unknown as IPhoto[])
        })
}

export const apiPropertyPhotoUpload = ( accessToken: string|undefined,
                                        propertyId: number)=> {
    const baseURL = getBaseURL()
    return {
        process: {
            url: `${baseURL}/properties/${propertyId}/photos`,
            headers: generateBearerToken(accessToken)
        },
        revert: {
            url: `${baseURL}/properties/${propertyId}/photos`,
            headers: generateBearerToken(accessToken)
        }
    }
}

export const apiUpdatePropertyPhoto = ( accessToken: string|undefined,
                                        id: number,
                                        photoId: number,
                                        payload: any,
                                        setData: Function) => {
    apiPost(accessToken, `/properties/${id}/photos/${photoId}`, payload)
        .then(response => {
            if (response === undefined) {
                response = {data: {}}
            }
            setData(response.data as unknown as IPhoto[])
        })
}

export const apiRemovePropertyPhoto = ( accessToken: string|undefined,
                                        id: number,
                                        photoId: number,
                                        setData: Function) => {
    apiDelete(accessToken, `/properties/${id}/photos/${photoId}`)
        .then(response => {
            if (response === undefined) {
                response = {data: {}}
            }
            setData(response.data as unknown as IPhoto[])
        })
}
