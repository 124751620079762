import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ILocation, Island} from 'types'
import {getMenuItemIcon, MenuItemType} from 'enums/Types'
import {handleNavLocations} from 'functions/NavFunctions'
import {reversedEnum} from "functions/CommonUtils";
import {highlightIfMatched} from './commonFormatter'

export const formatLocationResults = (
    location: ILocation,
    searchText: string|undefined,
    type: string,
    applyView: Function,
    setSelectedMenuItem: Function
): JSX.Element | undefined => {
    return (
        <div    id={`${type}_${location.id}`}
                key={`${location.name}_${type}_${location.id}`}
                className={'search-result-link'}>
            <div    className={'search-result-title'}
                    onClick={() => {
                        applyView('location', location.id)
                        setSelectedMenuItem(MenuItemType.LOCATIONS)
                        handleNavLocations()
                    }}>
                <FontAwesomeIcon icon={getMenuItemIcon(MenuItemType.LOCATIONS)} fixedWidth size={'sm'} />{' '}
                {highlightIfMatched(searchText, location.name)}
            </div>
            <div className={'search-result-description'}>
                Located on {highlightIfMatched(searchText, reversedEnum(Island)[location.island as Island])} and associated with{' '}
                {location.associatedProperties === 1
                    ? `${location.associatedProperties} property`
                    : `${location.associatedProperties ? location.associatedProperties : 0} properties`}.
            </div>
        </div>
    )
}