import React, { ReactNode } from 'react'
import './TopBar.scss'

interface ITopBarLeftProps {
    id?: string,
    className?: string,
    children?: ReactNode|undefined,
}

const TopBarLeft: React.FC<ITopBarLeftProps> = ({
    id = 'topBarLeft',
    className = 'top-bar-left',
    children,
}) => (
    <div id={id} key={id} className={`place-self-start w-full flex ${className}`}>
        {children}
    </div>
)

interface ITopBarRightProps {
    id?: string,
    className?: string,
    children?: ReactNode|undefined,
}

const TopBarRight: React.FC<ITopBarRightProps> = ({
    id = 'topBarRight',
    className = 'top-bar-right',
    children,
}) => (
    <div id={id} key={id} className={`place-self-end align-right flex ${className}`}>
        {children}
    </div>
)

interface ITopBarProps {
    id: string,
    children: any,
}

type TopBar = React.FC<ITopBarProps> & {
    Left: typeof TopBarLeft
    Right: typeof TopBarRight
}

const TopBar: TopBar = ({
    id,
    children = undefined,
}: ITopBarProps) => {

    return (
        <header id={id} className='top-bar'>
            <div className='top-bar-content'>
                {children}
            </div>
        </header>
    )
}

TopBar.Left = TopBarLeft
TopBar.Right = TopBarRight

export default TopBar