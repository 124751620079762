import React, {useEffect, useState} from 'react'
import GoogleMapReact from 'google-map-react'
import LocationPin from './LocationPin'

type Coordinates = {
    lat: number,
    lng: number,
}

interface IMapProps {
    label?: string,
    coordinates: Coordinates,
    zoomLevel: number,
    applySelectedCoordinates: Function,
}

export const Map: React.FC<IMapProps> = ({
    label,
    coordinates,
    zoomLevel,
    applySelectedCoordinates,
}) => {
    const [selectedCoords, setSelectedCoords] = useState<Coordinates>(coordinates)

    useEffect(() => {
        setSelectedCoords(coordinates)
    }, [coordinates])

    const handleClick = ({ lat, lng }: Coordinates) => {
        setSelectedCoords({ lat, lng });
        applySelectedCoordinates({ lat, lng })
    }

    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyDWgALlYtKcN_v9UZ82mHGv5V0wxOxKdLc' }}
            defaultCenter={selectedCoords}
            defaultZoom={zoomLevel}
            onClick={handleClick}>
            <LocationPin label={label} lat={selectedCoords.lat} lng={selectedCoords.lng} />
        </GoogleMapReact>
    )
}

export default Map