import React from 'react'

//-- FONT AWESOME
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faSpinner,
} from '@fortawesome/free-solid-svg-icons'

import './LoadingState.scss'

interface ILoadingStateProps {
    id: string,
}

export const LoadingState: React.FC<ILoadingStateProps> = ({
    id,
}: ILoadingStateProps) => {
    return (
        <div className={'loadingStateWrapper'}>
            <div className={'loadingStateContainer'}>
                <div className={'spinnerWrapper'}>
                    <FontAwesomeIcon icon={faSpinner} fixedWidth className={'spinner'} />
                </div>
                <span className={'text'}>
                    <span className={'highlight'}>//</span>{'Loading...'}
                </span>
            </div>
        </div>
    )
}