import { faChartLine, faHouse, faLocationDot, faListCheck, faAddressBook, faCalendarDays, faSearch, faUser, faGear, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import * as nav from 'functions/NavFunctions'


export enum MenuItemType {
    // DASHBOARD =  'dashboard',
    PROPERTIES = 'properties',
    LOCATIONS =  'locations',
    FEATURES =   'features',
    CONTACTS =   'contacts',
    VIEWINGS =   'viewings',
    PROFILE =    'profile',
    SETTINGS =   'settings',
    SEARCH =     'search',
}

export const menuItems: MenuItemType[] = [
    // MenuItemType.DASHBOARD,
    MenuItemType.PROPERTIES,
    MenuItemType.LOCATIONS,
    MenuItemType.FEATURES,
    MenuItemType.CONTACTS,
    MenuItemType.VIEWINGS,
]

export const getMenuItemIcon = (menuItemType: MenuItemType): any => {
    switch (menuItemType) {
        // case MenuItemType.DASHBOARD: return faChartLine
        case MenuItemType.PROPERTIES: return faHouse
        case MenuItemType.LOCATIONS: return faLocationDot
        case MenuItemType.FEATURES: return faListCheck
        case MenuItemType.CONTACTS: return faAddressBook
        case MenuItemType.VIEWINGS: return faCalendarDays
        case MenuItemType.SEARCH: return faSearch
    }
}

export const userMenuItems: IDropdownMenuItem[] = [
    {
        name: 'Profile',
        action: nav.handleNavProfile,
        icon: faUser,
    },
    {
        name: 'Settings',
        action: nav.handleNavSettings,
        icon: faGear,
    },
    {
        name: 'Logout',
        action: nav.handleLogout,
        icon: faPowerOff,
        separator: true,
    },
]

export interface IDropdownMenuItem {
    name: string,
    action: Function,
    icon: any,
    separator?: boolean,
}