import React, {ReactNode} from 'react'
import MuiButton from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Button.scss'

interface IButtonProps {
    id?: string|undefined,
    label?: any|undefined,
    onClick: Function,
    className?: string,
    disabled?: boolean,
    shadow?: boolean,
    children?: ReactNode,
    startIcon?: ReactNode,
    endIcon?: ReactNode,
}

type ButtonVariant = 'icon' | 'primary' | 'secondary' | 'generic' | 'custom'

const renderButton: any = (
    id: string,
    variant: ButtonVariant,
    label: any,
    onClick: Function,
    className: string,
    disabled: boolean,
    shadow: boolean,
    children?: ReactNode,
    startIcon?: ReactNode,
    endIcon?: ReactNode,
) => {

    const handleButtonClick = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        onClick && onClick(e)
    }

    return (
        <MuiButton
            id={id}
            variant={variant === 'icon' ? 'text' : (variant === 'primary' ? 'contained' : 'outlined')}
            className={`button ${shadow ? 'button-shadow' : ''} ${variant} ${className && className}`}
            style={{lineHeight: '1'}}
            onClick={(e) => {
                handleButtonClick(e)
            }}
            disabled={disabled}
            disableRipple={variant === 'icon'}
            startIcon={startIcon}
            endIcon={endIcon}
        >
            {variant === 'custom' ?
                    (children)
                :
                    (variant !== 'icon' ? label : (
                        <FontAwesomeIcon icon={label} fixedWidth size='lg'/>
                    ))
            }
        </MuiButton>
    )
}

export const Button: React.FC<IButtonProps> = ({
    id = undefined,
    label,
    onClick,
    className = undefined,
    disabled = false,
    shadow = false,

}: IButtonProps) => {
    const children: ReactNode = undefined
    const startIcon: ReactNode = undefined
    const endIcon: ReactNode = undefined
    return renderButton(id, 'generic', label, onClick, className, disabled, shadow, children, startIcon, endIcon)
}

export const PrimaryButton: React.FC<IButtonProps> = ({
    id = undefined,
    label,
    onClick,
    className = undefined,
    disabled = false,
    shadow = false,
    startIcon = undefined,
    endIcon = undefined,
}: IButtonProps) => {
    const children: ReactNode = undefined
    return renderButton(id, 'primary', label, onClick, className, disabled, shadow, children, startIcon, endIcon)
}

export const SecondaryButton: React.FC<IButtonProps> = ({
    id = undefined,
    label,
    onClick,
    className = undefined,
    disabled = false,
    shadow = false,
    startIcon = undefined,
    endIcon = undefined,
}: IButtonProps) => {
    const children: ReactNode = undefined
    return renderButton(id, 'secondary', label, onClick, className, disabled, shadow, children, startIcon, endIcon)
}

export const IconButton: React.FC<IButtonProps> = ({
    id = undefined,
    label,
    onClick,
    className = undefined,
    disabled = false,
    shadow = false,
}: IButtonProps) => {
    const children: ReactNode = undefined
    const startIcon: ReactNode = undefined
    const endIcon: ReactNode = undefined
    return renderButton(id, 'icon', label, onClick, className, disabled, shadow, children, startIcon, endIcon)
}

export const CustomButton: React.FC<IButtonProps> = ({
    id = undefined,
    label = undefined,
    onClick,
    className = undefined,
    disabled = false,
    shadow = false,
    children,
}: IButtonProps) => {
    const startIcon: ReactNode = undefined
    const endIcon: ReactNode = undefined
    return renderButton(id, 'custom', label, onClick, className, disabled, shadow, children, startIcon, endIcon)
}
