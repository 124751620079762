import React, {useEffect, useRef, useState} from 'react'

//-- AUTH0
import {useAccessToken} from 'hooks/useAccessToken'

//-- MATERIAL UI
import {
    Box,
    Grid,
    TextField
} from '@mui/material'

//-- FONT AWESOME
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faCircleChevronLeft,
    faSave
} from '@fortawesome/free-solid-svg-icons'

//-- PAGE COMPONENTS
import {PrimaryButton, SecondaryButton} from 'components/button/Button'
import {Growl} from 'components/growl/Growl'

//-- UTILITIES AND TYPES
import {GrowlType, IContact} from 'types'
import {growl, randomNumber} from 'functions/CommonUtils'

//-- API AND ACTIONS
import {apiUpsertContact} from 'api'

//-- STYLES
import './Contact.scss'


/***
 * INTERFACES
 */
interface IContactProps {
    id?: string,
    contact: IContact,
    setData: Function,
    data: IContact[],
    handleBack: Function,
    handleSave: Function,
}

export const Contact: React.FC<IContactProps> = ({
    id = 'contactPage',
    contact,
    setData,
    data,
    handleBack,
    handleSave,
}: IContactProps) => {
    //-- Local State
    const [accessToken] = useAccessToken()
    const [containerHeight, setContainerHeight] = useState(0)
    const [name, setName] = useState(contact.name)
    const [email1, setEmail1] = useState(contact.email1)
    const [email2, setEmail2] = useState(contact.email2)
    const [phone1, setPhone1] = useState(contact.phone1)
    const [phone2, setPhone2] = useState(contact.phone2)
    const [nameError, setNameError] = useState(false)
    const [growlMessage, setGrowlMessage] = useState<string>('')
    const [growlType, setGrowlType] = useState<GrowlType>()
    const [growlInstance, setGrowlInstance] = useState<number>(randomNumber())

    //-- Local References
    const  ref = useRef<HTMLDivElement>(null)


    /***
     * USE EFFECT HOOKS
     */
    useEffect(() => {
        updateContainerHeight()
        window.addEventListener('resize', updateContainerHeight)
        return () => window.removeEventListener('resize', updateContainerHeight)
    }, [])


    /***
     * UTILITY FUNCTIONS
     */
    const updateContainerHeight = () => {
        setContainerHeight(ref?.current?.clientHeight!)
    }

    const _handleSave = () => {
        const _nameError = name === undefined || name === null || name.length === 0

        setNameError(_nameError)

        if (contact) {
            if (!_nameError) {
                contact.name = name
                contact.email1 = email1
                contact.email2 = email2
                contact.phone1 = phone1
                contact.phone2 = phone2

                const payload: any = {
                    ...contact
                }
                apiUpsertContact(accessToken, setData, data, payload)
                handleSave()
            } else {
                growl('Missing Required Field(s)', 'error', setGrowlMessage, setGrowlType, setGrowlInstance)
            }
        }
    }

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
        setNameError(false)
    }


    /***
     * RENDER PROPS
     */
    const itemXs:number = 1
    const itemSm:number = 2
    const itemMd:number = 2
    const itemLg:number = 2

    return (
        <>
            <div id={id} className='page_container' ref={ref}>
                <Box component='form' noValidate autoComplete='off' className='contact_editor' style={{height: containerHeight}}>
                    <Grid container spacing={{ xs: 3, sm: 2, md: 3 }} marginBottom={3} columns={{ xs: 1 }}>
                        <Grid item xs={itemXs} sm={itemSm} md={itemMd} lg={itemLg}>
                            <TextField
                                required
                                error={nameError}
                                className='textInput'
                                label='Contact Name'
                                value={name}
                                inputProps={{ maxLength: 50 }}
                                variant='filled'
                                onChange={handleNameChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={{ xs: 3, sm: 2, md: 3 }} columns={{ xs: 1, sm: 2, md: 4, lg: 4 }}>
                        <Grid item xs={itemXs} sm={itemSm} md={itemMd} lg={itemLg}>
                            <TextField
                                className='textInput'
                                label='Email 1'
                                value={email1}
                                inputProps={{ maxLength: 200 }}
                                variant='filled'
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setEmail1(event.target.value)}}
                            />
                        </Grid>
                        <Grid item xs={itemXs} sm={itemSm} md={itemMd} lg={itemLg}>
                            <TextField
                                className='textInput'
                                label='Email 2'
                                value={email2}
                                inputProps={{ maxLength: 200 }}
                                variant='filled'
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setEmail2(event.target.value)}}
                            />
                        </Grid>
                        <Grid item xs={itemXs} sm={itemSm} md={itemMd} lg={itemLg}>
                            <TextField
                                className='textInput'
                                label='Phone 1'
                                value={phone1}
                                inputProps={{ maxLength: 50 }}
                                variant='filled'
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setPhone1(event.target.value)}}
                            />
                        </Grid>
                        <Grid item xs={itemXs} sm={itemSm} md={itemMd} lg={itemLg}>
                            <TextField
                                className='textInput'
                                label='Phone 2'
                                value={phone2}
                                inputProps={{ maxLength: 50 }}
                                variant='filled'
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setPhone2(event.target.value)}}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <div className='contact_editor_buttons'>
                    <SecondaryButton id={`${id}_back_button`} label='Back' startIcon={<FontAwesomeIcon icon={faCircleChevronLeft} />} onClick={handleBack}  />
                    <PrimaryButton id={`${id}_save_button`} label='Save' startIcon={<FontAwesomeIcon icon={faSave} />} onClick={_handleSave} />
                </div>
                <Growl id={`${id}_growl`} message={growlMessage} type={growlType} instance={growlInstance}/>
            </div>
        </>
    )
}