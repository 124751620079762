import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {getMenuItemIcon, MenuItemType} from 'enums/Types'
import {handleNavFeatures} from 'functions/NavFunctions'
import {IFeature} from 'types'
import {highlightIfMatched} from './commonFormatter'

export const formatFeatureResults = (
    feature: IFeature,
    searchText: string|undefined,
    type: string,
    applyView: Function,
    setSelectedMenuItem: Function
): JSX.Element | undefined => {
    return (
        <div    id={`${type}_${feature.id}`}
                key={`${feature.name}_${type}_${feature.id}`}
                className={'search-result-link'}>
            <div    className={'search-result-title'}
                    onClick={() => {
                        applyView('feature', feature.id)
                        setSelectedMenuItem(MenuItemType.FEATURES)
                        handleNavFeatures()
                    }}>
                <FontAwesomeIcon icon={getMenuItemIcon(MenuItemType.FEATURES)} fixedWidth size={'sm'} />{' '}
                {highlightIfMatched(searchText, feature.name)}
            </div>
            <div className={'search-result-description'}>
                This feature is associated with{' '}
                {feature.associatedProperties === 1
                    ? `${feature.associatedProperties} property`
                    : `${feature.associatedProperties ? feature.associatedProperties : 0} properties`}.
            </div>
        </div>
    )
}