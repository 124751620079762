import React, {useEffect, useState} from 'react'

//-- AUTH0
import {useAccessToken} from 'hooks/useAccessToken'

//-- MATERIAL UI
import {Popover, Stack, Box, ButtonGroup } from '@mui/material'
import {GridActionsCellItem, GridRowId, GridRowParams} from '@mui/x-data-grid'

//-- FONT AWESOME
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faCircleDollarToSlot,
    faEye,
    faEyeSlash,
    faF,
    faFilter,
    faHeartCircleCheck,
    faHeartCircleXmark,
    faImages,
    faPen,
    faTrash,
    faHouse,
    faEnvelope,
} from '@fortawesome/free-solid-svg-icons'

//-- TABLE AND TABLE DEFINITIONS
import {Table} from 'components/table/Table'
import {decodePropertyStatus, propertiesColumnDef} from 'metadata/TableDefinitions'

//-- PAGE COMPONENTS
import {PrimaryButton, SecondaryButton} from 'components/button/Button'
import {LoadingState} from 'components/loadingState/LoadingState'
import {Growl} from 'components/growl/Growl'
import {EmptyState} from 'components/emptyState/EmptyState'

//-- SUB PAGES
import {Property} from './Property'
import {Photos} from './Photos'

//-- UTILITIES AND TYPES
import {applyView, getViewId, growl, randomNumber, reversedEnum} from 'functions/CommonUtils'
import {
    FurnishedType, GrowlType,
    IProperty,
    PriceFreq,
    PropertyStatus,
    PropertyType,
    RentalPropertyTypes, SELECT_ONE
} from 'types'

//-- API AND ACTIONS
import {
    apiListProperties,
    apiDeleteProperty,
} from 'api'
import {
    applyPropertyFeatured,
    applyPropertyStatus,
    getPropertyStatus,
} from './actions'

//-- STYLES
import './Properties.scss'


/***
 * LOCAL CONSTANTS
 */
const EDIT_VIEW_ID= 'property'
const NEW_VIEW_ID= 'newProperty'
const PHOTO_VIEW_ID= 'propertyPhotos'


/***
 * INTERFACES
 */
interface IPropertiesProps {
    id?: string,
    setPageSubTitle: Function
}


/**
 * Functional Component - Properties
 * @param id
 * @param setPageSubTitle
 * @constructor
 */
export const Properties: React.FC<IPropertiesProps> = ({
    id = 'propertiesPage',
    setPageSubTitle,
}: IPropertiesProps) => {
    //-- Local State
    const [accessToken] = useAccessToken()
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
    const [propertyId, setPropertyId] = useState<number|string|undefined>(getViewId(EDIT_VIEW_ID))
    const [propertyPhotosId, setPropertyPhotosId] = useState<number|string|undefined>(getViewId(PHOTO_VIEW_ID))
    const [newPropertyType, setNewPropertyType] = useState<number|string|undefined>(getViewId(NEW_VIEW_ID))
    const [data, setData] = useState<IProperty[]>([])
    const [loaded, setLoaded] = useState(false)
    const [property, setProperty] = useState<IProperty|undefined>()
    const [featuredFilter, setFeaturedFilter] = useState<boolean>()
    const [growlMessage, setGrowlMessage] = useState<string>('')
    const [growlType, setGrowlType] = useState<GrowlType>()
    const [growlInstance, setGrowlInstance] = useState<number>(randomNumber())

    //-- Local Variables
    const showNewPropertyPopover = Boolean(anchorEl)


    /***
     * USE EFFECT HOOKS
     */
    useEffect(() => {
        setLoaded(false)
        if (accessToken) {
            if (featuredFilter !== undefined) {
                apiListProperties(accessToken, setData, setLoaded, featuredFilter)
            } else {
                apiListProperties(accessToken, setData, setLoaded)
            }
        }
    }, [featuredFilter, accessToken])

    useEffect(() => {
        if (data && (propertyId || propertyPhotosId)) {
            const id = propertyId || propertyPhotosId
            const value: IProperty | undefined = data.find((property) => property.id === id)
            setProperty(value)
            if (propertyId) {
                setPageSubTitle(value && `${PropertyType[value.propertyType as keyof typeof PropertyType]} Editor`)
            }
            if (propertyPhotosId) {
                setPageSubTitle(value && `${value.propertyName} Photos`)
            }
        } else if (newPropertyType) {
            initNewProperty(newPropertyType.toString())
        } else {
            setProperty(undefined)
            setPropertyId(undefined)
            setPropertyPhotosId(undefined)
            setNewPropertyType(undefined)
            setPageSubTitle(undefined)
        }
    }, [propertyId, propertyPhotosId, newPropertyType, data])


    /***
     * UTILITY FUNCTIONS
     */
    const initNewProperty = (propertyTypeKey: string) => {
        const initProperty: IProperty = {
            propertyName: '',
            propertyOwner: {id: -1, name: SELECT_ONE},
            bedrooms: 1,
            bathrooms: 1,
            floors: 1,
            acreage: 1,
            location: {id: -1, name: SELECT_ONE},
            coordinates: {lat: 18.425572163144704, lng: -64.61883209445236},
            features: [],
            propertyType: propertyTypeKey,
            furnishedType: reversedEnum(FurnishedType)[FurnishedType.NOT_FURNISHED],
            status: reversedEnum(PropertyStatus)[PropertyStatus.INACTIVE],
            featured: false,
            price: 100,
            priceFreq: reversedEnum(PriceFreq)[PriceFreq.MONTHLY],
            caption: '',
            description: '',
            photos: [],
        }
        setProperty(initProperty)
        applyView(NEW_VIEW_ID, propertyTypeKey)
        setPageSubTitle(`New ${PropertyType[propertyTypeKey as keyof typeof PropertyType]}`)
        handleCloseNewPropertyPopover()
    }

    const handleShowNewPropertyPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseNewPropertyPopover = () => {
        setAnchorEl(null)
    }

    const PropertyTypeSelection = ()  => {
        const selection = Object.keys(PropertyType).map((propertyTypeKey) => (
            <div key={propertyTypeKey}
                 className='new_property_menu'
                 onClick={() => initNewProperty(propertyTypeKey)}
            >
                {PropertyType[propertyTypeKey as keyof typeof PropertyType]}
            </div>
        ))
        return <>{selection}</>
    }

    const handleResetEditor = () => {
        setProperty(undefined)
        setPropertyId(undefined)
        setPropertyPhotosId(undefined)
        setNewPropertyType(undefined)
        setPageSubTitle(undefined)
        applyView(EDIT_VIEW_ID, undefined)
        applyView(NEW_VIEW_ID, undefined)
        applyView(PHOTO_VIEW_ID, undefined)
    }

    const isRentalProperty = (type: string): boolean => {
        return RentalPropertyTypes.includes(PropertyType[type as keyof typeof PropertyType])
    }

    const handleSave = () => {
        growl(`${property ? property.propertyName : 'Property'} Saved Successfully`, 'success', setGrowlMessage, setGrowlType, setGrowlInstance)
        handleResetEditor()
    }


    /***
     * CALLBACK FUNCTIONS
     */
    const editProperty = React.useCallback(
        (property: IProperty) => () => {
            if (property?.id) {
                setPropertyId(property.id)
                setNewPropertyType(undefined)
                setProperty(property)
                setPropertyPhotosId(undefined)
                applyView(EDIT_VIEW_ID, property?.id)
                applyView(NEW_VIEW_ID, undefined)
                applyView(PHOTO_VIEW_ID, undefined)
            } else {
                setTimeout(() => {
                    growl('Unable to edit selected property', 'error', setGrowlMessage, setGrowlType, setGrowlInstance)
                }, 100)
            }
        },
        [data],
    )

    const managePhotos = React.useCallback(
        (property: IProperty) => () => {
            if (property?.id) {
                setPropertyId(property.id)
                setProperty(property)
                setPropertyPhotosId(property.id)
                applyView(EDIT_VIEW_ID, undefined)
                applyView(NEW_VIEW_ID, undefined)
                applyView(PHOTO_VIEW_ID, property?.id)
            } else {
                growl('Unable to edit selected property photos', 'error', setGrowlMessage, setGrowlType, setGrowlInstance)
            }
        },
        [data],
    )

    const deleteProperty = React.useCallback(
        (id: GridRowId) => () => {
            apiDeleteProperty(accessToken, id, setData, data, 'Property Successfully Deleted', setGrowlMessage, setGrowlType, setGrowlInstance)
        },
        [data],
    )

    const featurePropertyToggle = React.useCallback(
        (id: GridRowId) => () => {
            const index: number = data.findIndex((row): boolean => row.id === id)
            if (index !== -1) {
                const featured = !data[index].featured
                applyPropertyFeatured(accessToken, id, featured, setData, data, featuredFilter, setLoaded, `Successfully ${featured ? 'Featured' : 'Removed Feature for'} Property`, setGrowlMessage, setGrowlType, setGrowlInstance)
            }

        }, [data],
    )

    const activateProperty = React.useCallback(
        (id: GridRowId) => () => {
            applyPropertyStatus(accessToken, id, PropertyStatus.ACTIVE, setData, data, `Successfully Activated Property`, setGrowlMessage, setGrowlType, setGrowlInstance)
        }, [data],
    )

    const deactivateProperty = React.useCallback(
        (id: GridRowId) => () => {
            applyPropertyStatus(accessToken, id, PropertyStatus.INACTIVE, setData, data, `Successfully Deactivated Property`, setGrowlMessage, setGrowlType, setGrowlInstance)
        }, [data],
    )

    const markAsRented = React.useCallback(
        (id: GridRowId) => () => {
            applyPropertyStatus(accessToken, id, PropertyStatus.RENTED, setData, data, `Successfully Marked Property as Rented`, setGrowlMessage, setGrowlType, setGrowlInstance)
        }, [data],
    )

    const markAsSold = React.useCallback(
        (id: GridRowId) => () => {
            applyPropertyStatus(accessToken, id, PropertyStatus.SOLD, setData, data, `Successfully Marked Property as Sold`, setGrowlMessage, setGrowlType, setGrowlInstance)
        }, [data],
    )


    /***
     * ICONS
     */
    const editActionIcon = (propertyName: string) => <FontAwesomeIcon icon={faPen} title={`Edit ${propertyName}`} className='datagrid-action-icon' />
    const deleteActionIcon = (propertyName: string) => <FontAwesomeIcon icon={faTrash} title={`Delete ${propertyName}`} className='datagrid-action-icon' />
    const featuredIcon = (propertyName?: string, active?: boolean) => (
        propertyName ?
            <FontAwesomeIcon icon={faF} fixedWidth={true} title={`${propertyName} ${active ? 'is' : 'was'} Featured`} className={`datagrid-action-icon featured_icon ${active ? 'active' : ''}`} />
        :
            <FontAwesomeIcon icon={faF} fixedWidth={true} className={`datagrid-action-icon featured_icon_static`} />
    )
    const filterIcon = (
        <FontAwesomeIcon icon={faFilter} fixedWidth={true} />
    )
    const managePhotosActionIcon = (propertyName: string) => <FontAwesomeIcon icon={faImages} title={`Manage photos for ${propertyName}`} className='datagrid-action-icon' />
    const featureActionIcon = (featured: boolean) => <FontAwesomeIcon icon={! featured ? faHeartCircleCheck : faHeartCircleXmark} className='datagrid-action-menu-icon' fixedWidth />
    const activateActionIcon = (activate: boolean) => <FontAwesomeIcon icon={activate ? faEye : faEyeSlash} className='datagrid-action-menu-icon' fixedWidth />
    const rentedSoldActionIcon = () => <FontAwesomeIcon icon={faCircleDollarToSlot} className='datagrid-action-menu-icon' fixedWidth />
    const emailOwnerActionIcon = () => <FontAwesomeIcon icon={faEnvelope} className='datagrid-action-menu-icon' fixedWidth />


    /***
     * DATAGRID COLUMNS
     */
    const columns= [...propertiesColumnDef,
        {
            field: 'status',
            headerName: 'Status',
            width: 95,
            renderCell: (params: any) => (
                <>
                    <div className='property-status-label'>{decodePropertyStatus(params.row.status)}</div>
                    {(params.row.featured) ? featuredIcon(params.row.propertyName, (params.row.status !== getPropertyStatus(PropertyStatus.INACTIVE))) : undefined}
                </>
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            type: 'actions',
            width: 150,
            getActions: (params: GridRowParams<any>) => [
                <GridActionsCellItem
                    icon={editActionIcon(params.row.propertyName)}
                    label='Edit'
                    onClick={editProperty(params.row)}
                />,
                <GridActionsCellItem
                    icon={managePhotosActionIcon(params.row.propertyName)}
                    label='Manage Photos'
                    onClick={managePhotos(params.row)}
                />,
                <GridActionsCellItem
                    icon={deleteActionIcon(params.row.propertyName)}
                    label='Delete'
                    onClick={deleteProperty(params.id)}
                />,
                <GridActionsCellItem
                    icon={featureActionIcon(params.row.featured)}
                    label={(! params.row.featured) ? 'Feature' : 'Remove Feature'}
                    onClick={featurePropertyToggle(params.id)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={activateActionIcon(params.row.status === getPropertyStatus(PropertyStatus.INACTIVE))}
                    label={(params.row.status === getPropertyStatus(PropertyStatus.INACTIVE)) ? 'Activate' : 'Deactivate'}
                    onClick={(params.row.status === getPropertyStatus(PropertyStatus.INACTIVE)) ? activateProperty(params.id) : deactivateProperty(params.id)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={rentedSoldActionIcon()}
                    label={(isRentalProperty(params.row.propertyType)) ? 'Mark as Rented' : 'Mark as Sold'}
                    onClick={(isRentalProperty(params.row.propertyType)) ? markAsRented(params.id) : markAsSold(params.id)}
                    showInMenu
                    disabled={params.row.status === getPropertyStatus(PropertyStatus.SOLD) || params.row.status === getPropertyStatus(PropertyStatus.RENTED)}
                />,
                <GridActionsCellItem
                    icon={emailOwnerActionIcon()}
                    label={`Email Owner`}
                    onClick={() => {window.location.href = `mailto:${params.row.propertyOwner.name} <${params.row.propertyOwner.email1 || params.row.propertyOwner.email2}>?subject=Regarding ${params.row.propertyName}`}}
                    showInMenu
                    disabled={!params.row.propertyOwner.email1 && !params.row.propertyOwner.email2}
                />,
            ]
        }
    ]

    return (
        <>
            {loaded  ? (
                <>
                    {property ? (
                        <>
                            {propertyPhotosId ? (
                                <Photos id='photosPage' property={property} handleBack={handleResetEditor}/>
                            ) : (
                                <Property   id='propertyPage' property={property} setData={setData} data={data}
                                            handleBack={handleResetEditor} handleSave={handleSave}/>
                            )}
                        </>
                    ) : propertyId === undefined && newPropertyType === undefined && propertyPhotosId === undefined && (
                        <>
                            {data?.length || featuredFilter !== undefined ? (
                                <>
                                    <div id={`${id}_properties_header`} className='base_header'>
                                        <div className='base_filters'>
                                            <div className='property_filters_label'>
                                                {filterIcon}
                                                Filter Properties:
                                            </div>
                                            <ButtonGroup className='property_filters_buttongroup'>
                                                <SecondaryButton    id={`${id}_filterAll`} onClick={() => setFeaturedFilter(undefined)}
                                                                    label='All' className={featuredFilter === undefined ? 'featured_filter_selected' : 'featured_filter_unselected'} />

                                                <SecondaryButton    id={`${id}_filterOnlyFeatured`} onClick={() => setFeaturedFilter(true)}
                                                                    label='Featured' className={featuredFilter === true ? 'featured_filter_selected' : 'featured_filter_unselected'}
                                                />

                                                <SecondaryButton    id={`${id}_filterNotFeatured`} onClick={() => setFeaturedFilter(false)}
                                                                    label='Not Featured' className={featuredFilter === false ? 'featured_filter_selected' : 'featured_filter_unselected'} />
                                            </ButtonGroup>
                                        </div>
                                        <div className='property_controls'>
                                            <PrimaryButton  id={`${id}_newProperty`} onClick={handleShowNewPropertyPopover}
                                                            startIcon={<FontAwesomeIcon icon={faHouse} />}
                                                            label='New Property' />
                                        </div>
                                    </div>
                                    <Table id={`${id}_properties`} columns={columns} rows={data} />
                                </>
                            ) : (
                                <EmptyState id={'emptyState'} icon={faHouse}
                                            caption={'No properties found'}
                                            text={'Click the button below to add a new property.'}
                                            action={handleShowNewPropertyPopover}
                                            actionLabel={'Add New Property'}
                                />
                            )}
                            <Popover
                                id={`${id}_newPropertySelection`}
                                open={showNewPropertyPopover}
                                onClose={handleCloseNewPropertyPopover}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: !data?.length ? 'top' : 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: data?.length ? 'top' : 'bottom',
                                    horizontal: 'center',
                                }}
                            >
                                <Box sx={{ minWidth: '1rem' }}>
                                    <Stack spacing={0.5}>
                                        <PropertyTypeSelection />
                                    </Stack>
                                </Box>
                            </Popover>
                        </>
                    )}
                </>
            ) : (
                <LoadingState id={`${id}_loading`}/>
            )}
            <Growl id={`${id}_growl`} message={growlMessage} type={growlType} instance={growlInstance}/>
        </>
    )
}