import {IContact} from './contact'
import {IProperty} from './property'

export interface IViewing {
    id?: number,
    client: IContact,
    schedule: string,
    status: string,
    property: IProperty|null,
    cancelled?: boolean,
}

export enum ViewingStatus {
    SCHEDULED = 'Scheduled',
    IN_PROGRESS = 'In Progress',
    COMPLETED = 'Completed',
    CANCELLED = 'Cancelled',
}
